@import "../../../../../styles/_vars"
    
.ContainerAndExpanded
    display: flex
    flex-direction: column
    width: 95%
    animation: showDiv 1s forwards

.Container
    font-size: 20px
    font-weight: bold
    height: 50px
    width: 95%
    display: flex
    justify-content: space-between
    align-items: center
    cursor: pointer
    animation: showDiv 1s forwards

.Adress
    display: flex
    align-items: center
    gap: 10px
    font-size: 16px
    font-weight: normal
    width: 500px

.LeftContentContainer
    display: flex
    gap: 30px
    align-items: center

.ItemsList
    display: flex
    flex-direction: column
    gap: 10px
