
@import "../../styles/_vars"

.Container
    width: 100vw
    height: 100vh
    position: fixed
    display: flex
    @media screen and (max-width: $end_mobile_width)
        flex-direction: column

.ImageBlock
    width: 100%
    display: flex
    background-image: url("../../../src/assets/images/login_banner.svg")
    background-repeat: no-repeat
    background-size: cover
    background-position: top
    @media screen and (max-width: $end_mobile_width)
        display: none

.LoginBlock
    min-width: 620px
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 20px
    @media screen and (max-width: $end_mobile_width)
        min-width: unset

.Logo
    height: 110px
    width: 110px

.Title
    font-size: 40px
    @media screen and (max-width: $end_mobile_width)
        font-size: 32px

.InLoginWrapper
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px
    width: 75%
