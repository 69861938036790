@import "../../../styles/_vars"

.Container
    display: flex
    flex-direction: column
    align-items: center
    width: 95%

.HeaderAndButtonWrapper
    display: flex
    align-items: center
    justify-content: space-between
    width: 95%

.Header
    display: flex
    gap: 10px
    font-size: 36px
    font-weight: bold
    height: 42px
    margin: 20px 0
    @media screen  and (max-width: $end_tablet_width)
        font-size: 24px
    @media screen and (max-width: $end_mobile_width)
        font-size: 20px
        flex-direction: column
        margin-bottom: 30px

.ListContainer
    display: flex
    width: 95%
    height: 100%
    gap: 30px
    align-items: flex-start
    flex-direction: column

.PassImage
    position: absolute
    right: 50px
    @media screen and (max-width: $end_tablet_width)
        width: 120px
        right: 20px
        top: 120px
    @media screen and (max-width: $end_mobile_width)
        display: none
