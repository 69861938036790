@import "../../../../styles/_vars"

.Container
    height: 74px
    display: flex
    width: 95%
    align-items: center
    justify-content: space-between
    animation: showDiv 1s forwards
    border: solid 1px #E7EBEE
    &:hover
        box-shadow: 0px 5px 9px 0px rgba(14, 32, 81, 0.2)
    @media screen and (max-width: $end_tablet_width)
        width: 300px
        height: 246px
        flex-direction: column
        justify-content: center
        align-items: center
        gap: 10px

.Bold20
    font-size: 16px
    font-weight: bold
    background-color: transparent
    min-width: 150px
    display: flex
    align-items: center
    gap: 10px
    @media screen and (max-width: $end_tablet_width)
        min-width: unset

.Regular16
    font-size: 16px
    font-weight: normal
    min-width: 150px
    display: flex
    align-items: center
    @media screen and (max-width: $end_tablet_width)
        min-width: unset

.Role
    @extend  .Regular16

.UserInfoWrapper
    display: flex
    margin-left: 20px
    gap: 20px
    @media screen and (max-width: $end_tablet_width)
        flex-direction: column
        gap: 10px
        margin-left: unset
        width: 80%

.StatusAndButtonsWrapper
    display: flex
    margin-right: 20px
    gap: 10px
    align-items: center
    @media screen and (max-width: $end_mobile_width)
        margin-right: unset

.VerticalLine
    border-left: solid 1px #D1D4D7
    height: 30px

.LoginAndStatus
    display: flex
    align-items: center
    justify-content: center
    min-width: 200px
    justify-content: space-between
    gap: 20px
    @media screen and (max-width: $end_tablet_width)
        min-width: unset

.Login
    display: flex
    align-items: center
    font-weight: bold
    font-size: 16px
    width: 220px
    @media screen and (max-width: $end_tablet_width)
        width: 95%

.Fio
    display: flex
    align-items: center
    font-size: 16px
    width: 450px
    @media screen and (max-width: $end_tablet_width)
        width: 95%

.Active
    display: flex
    margin-right: 10px

.HorizontalLine
    @media screen and (max-width: $end_tablet_width)
        width: 90%
        height: 0px
        border-top: solid 1px #D1D4D7
