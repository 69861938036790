@import "../../styles/_vars" 

.Container
    display: flex
    flex-direction: column
    align-items: center
    width: 90%
   

.ListContainer
    display: flex
    flex-wrap: wrap
    justify-content: center
    height: 100%
    gap: 10px
    width: 100%

.HeaderWrapper
    display: flex
    align-items: center
    justify-content: space-between
    width: 95%
    @media screen and (max-width: $end_mobile_width)
        flex-direction: column


.Message
  font-size: 16px
  height: 400px
  display: flex
  align-items: center
  justify-content: center
  width: 300px
  text-align: center

