
@import "../../../../../styles/_vars"

.Container
    width: 620px
    height: 100vh
    background-color: white
    display: flex
    align-items: center
    justify-content: center
    border-radius: 15px
    @media screen and (max-width: $end_mobile_width)
        width: 100vw

.ImageSure
    width: 374px
    height: auto
    @media screen and (max-width: $end_mobile_width)
        width: 220px

.Title
    font-size: 40px
    text-align: center

.TitleSure
    font-size: 32px
    font-weight: bold
    @media screen and (max-width: $end_mobile_width)
        font-size: 22px

.CommentSure
    font-size: 20px
    font-weight: 600
    width: 100%
    text-align: center
    @media screen and (max-width: $end_mobile_width)
        font-size: 16px

.ButtonsContainer
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 10px

.InFormWrapper
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    flex-direction: column
    gap: 10px
    width: 70%
    @media screen and (max-width: $end_mobile_width)
        gap: 5px
