@import "../../../../../styles/_vars"

.Container
    height: 50px
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    border: solid 1px #E7EBEE
    animation: showDiv 1s forwards


.Bold16
    font-weight: bold
    font-size: 16px

.ContentWrapper
    width: 100%
    display: flex
    margin: 0 20px
    justify-content: space-between
    gap: 10px
    font-size: 16px
 

.LeftSide
    display: flex
    justify-content: flex-start
    gap: 10px

.Width300
    min-width: 300px
    display: flex
    align-items: center
    justify-content: center
 

.Width200
    @extend .Width300
    min-width: 200px
.Width50
    @extend .Width300
    min-width: 50px
.Width100
    @extend .Width300
    min-width: 50px

.Exits
    @extend .Width200
    gap: 5px

.HoursNumber
    font-size: 20px
    font-weight: bold

.HoursText
    @extend .HoursNumber
    font-size: 14px
    display: flex
    align-items: center

.HoursWrapper
    background-color: black
    color: white
    border-radius: 5px
    padding: 5px

.Hours
    display: flex
    align-items: center
    width: 500px
    gap: 10px

.Wrapper
    display: flex
    gap: 10px

.HoursBlock
    display: flex
    gap: 5px
    background-color: black
    color: white
    border-radius: 5px
    padding: 5px
    width: max-content

.Rur
    font-size: 14px
    font-weight: bold

.Sum
    display: flex
    align-items: center
    justify-content: space-between
    gap: 5px
    width: 200px

.Period
    display: flex
    align-items: center
    justify-content: left
    width: 300px
