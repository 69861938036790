@import "../../../styles/_vars"

.Container
    height: 42px
    display: flex
    align-items: center
    justify-content: flex-start
    margin: 20px 0

.HeaderTextWrapper
    display: flex
    flex-wrap: wrap

.Part1
    font-size: 36px
    margin-right: 10px
    @media screen  and (max-width: $end_tablet_width)
        font-size: 24px

.Part2
    @extend .Part1
    font-weight: bold

.TitleBlock
    background-color: #F1F5FB
    width: max-content
    padding: 0 15px
    height: 40px
    border-radius: 50px
    display: flex
    align-items: center
    justify-content: center
    gap: 5px

.Title
    @media screen  and (max-width: $end_tablet_width)
      display: none
