
.CardContainer
    width: 300px
    z-index: 10
    animation: SlideLeft 0.5s
    animation-fill-mode: forwards
    cursor: pointer

.CardContainerExit
    @extend .CardContainer
    animation: slideRight 0.5s
    animation-fill-mode: forwards

.Container
    border-radius: 15px
    width: 260px
    height: 80px
    padding: 20px
    background-color: rgba(245, 245, 245 ,0.9 )
    display: flex
    flex-direction: column

.Message
    font-size: 12px
    min-height: 60px
    width: 260px

.ClickToHide
    font-size: 10px
    width: 260px
    display: flex
    justify-content: flex-end

    margin-top: 5px

.StatusBar
    width: 260px
    height: 10px
    background-color: red
    border-radius: 5px
    opacity: 0.5

.StatusBarPositive
    @extend .StatusBar
    background-color: green

@keyframes slideRight
    0%
        width: 100%

    100%
        width: 0&

@keyframes SlideLeft
    0%
        width: 0%

    100%
        width: 100%
