.Container
    position: absolute
    right: 0
    top: 10px
    width: 300px
    display: flex
    flex-direction: column
    gap: 10px
    align-items: flex-end
    justify-content: flex-start
    overflow: hidden
