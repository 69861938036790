@import "../../../../styles/_vars"

.Container
    height: 74px
    width: 95%
    display: flex
    align-items: center
    justify-content: center
    border: solid 1px #E7EBEE
    font-size: 16px
    font-weight: bold
    animation: showDiv 1s forwards
    &:hover
        box-shadow: 0px 5px 9px 0px rgba(14, 32, 81, 0.2)
    @media screen and (max-width: $end_tablet_width)
        width: 310px
        height: 350px
        flex-direction: column
        justify-content: center
        align-items: flex-start
        gap: 10px

.Name
    font-size: 16px
    font-weight: normal

.ContentWrapper
    display: flex
    justify-content: space-between
    align-items: center
    width: 95%
    gap: 10px
    @media screen and (max-width: $end_tablet_width)
        flex-direction: column
        gap: 10px
        margin-left: unset
        width: 90%

.ContentLeftWrapper
    display: flex
    gap: 10px
    align-items: center
    @media screen and (max-width: $end_tablet_width)
        flex-direction: column
        align-items: flex-start
        gap: 10px
        margin-left: unset
        width: 80%

.DateTimeWrapper
    display: flex
    align-items: center
    justify-content: center
    gap: 10px

.Time
    width: 52px
    height: 29px
    color: white
    background-color: black
    border-radius: 5px
    display: flex
    align-items: center
    justify-content: center

.DateTime
    display: flex
    align-items: center
    justify-content: space-between
    gap: 10px
    font-size: 16px
    font-weight: bold
    width: 220px

.DateTitle
    font-weight: normal

.WayContainer
    display: flex
    align-items: center
    gap: 10px
    padding: 0 10px
    @media screen and (max-width: $end_tablet_width)
        flex-direction: column
        align-items: flex-start

.In
    color: #46A053
    width: 240px
    @media screen and (max-width: $end_tablet_width)
        width: unset

.Out
    color: #F6583E
    width: 240px
    @media screen and (max-width: $end_tablet_width)
        width: unset

.IconAndId
    display: flex
    gap: 5px
    align-items: center

.DetailsButton
    width: 130px
    height: 40px
    background-color: #46A053
    color: white
    font-size: 16px
    text-align: center
    display: flex
    align-items: center
    justify-content: center
    font-weight: normal
    cursor: pointer

.Id
    display: flex
    gap: 10px
    width: 200px
    @media screen and (max-width: $end_tablet_width)
        flex-direction: column

.StatusAndButtonsWrapper
    display: flex
    gap: 15px
    align-items: center
    justify-content: flex-end
    width: 300px

.Cost
    text-align: left

.Ways
    display: flex
    flex-direction: column
    gap: 10px
