.Container
    display: flex
    align-items: center
    gap: 10px
    font-size: 18px

.CheckBox
    -ms-transform: scale(1.5) /* IE */
    -moz-transform: scale(1.5) /* FF */
    -webkit-transform: scale(1.5) /* Safari and Chrome */
    -o-transform: scale(1.5) /* Opera */
    transform: scale(1.5)
