@import "../../../../../styles/_vars"
.Container
    height: 74px
    width: 95%
    display: flex
    align-items: center
    justify-content: center
    border: solid 1px #E7EBEE
    animation: showDiv 1s forwards
    &:hover
        box-shadow: 0px 5px 9px 0px rgba(14, 32, 81, 0.2)
    @media screen and (max-width: $end_tablet_width)
        width: 300px
        height: 246px
        flex-direction: column
        justify-content: center
        gap: 10px

.ContentWrapper
    width: 100%
    display: flex
    margin: 0 20px
    justify-content: left
    align-items: center
    gap: 30px
    font-size: 16px
    @media screen and (max-width: $end_tablet_width)
        flex-direction: column
        gap: 10px
        margin-left: unset
        width: 80%
        align-items: flex-start

.Comment
    display: flex
    align-items: left
    width: 400px
    font-size: 16px
    @media screen and (max-width: $end_tablet_width)
        width: unset

.StatusAndButtonsWrapper
    display: flex
    gap: 10px
    align-items: center
    justify-content: center
    margin-right: 20px

.Service
    display: flex
    align-items: center
    gap: 5px
    font-size: 16px
    color: #1C86F1
    border-right: solid 1px #D1D4D7

.UnCheckedContainer
    height: 24px
    width: 24px
    border-radius: 6px
    border: solid 1px #E3E3E6
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer

.CheckedContainer
    @extend .UnCheckedContainer
    background-color: #1C86F1

.Id
    font-size: 16
    font-weight: bold

.Active
    display: flex
    margin-right: 10px

.VerticalLine
    border-left: solid 1px #D1D4D7
    height: 30px

.ParkingId
    display: flex
    gap: 10px
