@import "../../styles/_vars"

.Container
  display: flex
  flex-direction: column
  align-items: center
  width: 90%
  @media screen and (max-width: $end_mobile_width)
    width: 100%

.ListContainer
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  gap: 10px
  align-items: center
  @media screen and (max-width: $end_tablet_width)
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    

.HeaderAndButtonWrapper
  display: flex
  align-items: center
  justify-content: space-between
  width: 95%
  @media screen and (max-width: $end_mobile_width)
    flex-direction: column
    align-items: flex-start
    justify-content: unset
    


.Message
  font-size: 16px
  height: 400px
  display: flex
  align-items: center
  justify-content: center
  width: 300px
  text-align: center
