@import "../../../../../styles/_vars"

.Card
    width: 500px
    height: 580px
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 30px

.ForBorder
    height: 0px
    width: 100%
    border-top: solid 1px #D1D4D7

.BarrierContainer
    width: 480px
    height: 68px
    background-color: #F2F7F9
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 10px

.BarrierInfo
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between

.TimeInfo
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between

.Time
    width: 52px
    height: 29px
    color: white
    background-color: black
    border-radius: 5px
    display: flex
    align-items: center
    justify-content: center

.DateTime
    width: 150px
    display: flex
    align-items: center
    display: flex
    align-items: center
    justify-content: center
    gap: 10px
    font-size: 16px
    font-weight: bold
  

.DateTimeWrapper
    display: flex
    align-items: center
    justify-content: center
    gap: 10px

.Value
    font-size: 16px
    font-weight: bold
    display: flex
    align-items: center
    gap: 10px

.ValueIn
    @extend .Value
    color: #46A053

.ValueOut
    @extend .Value
    color: #F6583E

.Name
    @extend .Value
    font-weight: normal
.Rur
    font-size: 14px
    font-weight: bold
.Payment
    @extend .Value
    font-size: 20px
    width: 100%
    justify-content: space-between
.Sum
