@import "../../../../../../../styles/_vars"

.Link
    font-size: 16px
    color: #0F0F1E
    height: 55px
    display: flex
    align-items: center
    border-radius: 100px
    margin-left: 20px
    padding-left: 40px
    cursor: pointer
    animation: showDiv 1s forwards

.LinkActive
    @extend .Link
    background-color: #1C86F1
    color: white
    cursor: unset

.MenuArrow
    position: absolute
    left: 250px


