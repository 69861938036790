@import "../../../styles/_vars"
.Container
    width: 130px
    height: 40px
    background-color: #46A053
    color: white
    font-size: 16px
    text-align: center
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
 

.ContainerPass
    @extend .Container
    background-color: #1C86F1

.ContainerNotFound
    @extend .Container
    background-color: red
