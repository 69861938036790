@import "../../../../styles/_vars"

.Container
    height: 74px
    width: 95%
    display: flex
    align-items: center
    justify-content: center
    border: solid 1px #E7EBEE
    &:hover
        box-shadow: 0px 5px 9px 0px rgba(14, 32, 81, 0.2)
    @media screen and (max-width: $end_tablet_width)
        width: 300px
        height: 246px
        flex-direction: column
        justify-content: center
        align-items: left
        gap: 10px

.ContentWrapper
    width: 100%
    display: flex
    margin: 0 20px
    justify-content: left
    align-items: center
    gap: 10px
    font-size: 16px
    @media screen and (max-width: $end_tablet_width)
        flex-direction: column
        gap: 10px
        margin-left: unset
        width: 80%
        align-items: unset

.StatusAndButtonsWrapper
    display: flex
    gap: 15px
    align-items: center
    justify-content: center
    margin-right: 20px
    @media screen and (max-width: $end_mobile_width)
        margin-right: unset

.TimeInfo
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    @media screen and (max-width: $end_tablet_width)
        width: unset

.Time
    width: 52px
    height: 29px
    color: white
    background-color: black
    border-radius: 5px
    display: flex
    align-items: center
    justify-content: center

.DateTime
    width: 400px
    display: flex
    align-items: center
    display: flex
    align-items: center
    justify-content: center
    gap: 10px
    font-size: 16px
    font-weight: bold
    @media screen and (max-width: $end_tablet_width)
        width: unset

.DateTimeWrapper
    display: flex
    align-items: center
    justify-content: center
    gap: 10px

.Value
    font-size: 16px
    font-weight: bold
    display: flex
    align-items: center
    gap: 10px
    width: 100px
    @media screen and (max-width: $end_tablet_width)
        width: unset
        align-items: unset

.Name
    @extend .Value
    width: 400px
    @media screen and (max-width: $end_tablet_width)
        width: unset

.NameTitle
    font-weight: normal

.VerticalLine
    border-left: solid 1px #D1D4D7
    height: 30px
    @media screen and (max-width: $end_tablet_width)
        display: none

.DateTitle
    font-weight: normal
