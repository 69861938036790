@import "../../../../styles/_vars"

.Container
    height: 74px
    width: 95%
    display: flex
    align-items: center
    justify-content: center
    border: solid 1px #E7EBEE
    animation: showDiv 1s forwards
    &:hover
        box-shadow: 0px 5px 9px 0px rgba(14, 32, 81, 0.2)
    @media screen and (max-width: $end_tablet_width)
        width: 300px
        height: 350px
        flex-direction: column
        justify-content: center
        align-items: left
        gap: 10px

.InItemWrapper
    display: flex
    align-items: center
    justify-content: space-between
    gap: 20px
    width: 95%
    @media screen and (max-width: $end_tablet_width)
        flex-direction: column
        gap: 10px
        margin-left: unset

.ContentWrapper
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 20px
    @media screen and (max-width: $end_tablet_width)
        flex-direction: column
        gap: 10px
        margin-left: unset
        width: 80%
        align-items: unset

.Common
    display: flex
    align-items: center
    max-height: 45px
    overflow: hidden
    font-size: 16px
    font-weight: bold

.Id
    @extend .Common
    width: 100px
.Name
    @extend .Common
    width: 150px
    @media screen and (max-width: $end_tablet_width)
        width: unset

.Phone
    @extend .Name
    width: 150px
    @media screen and (max-width: $end_tablet_width)
        width: unset

.Contact
    @extend .Common
    width: 200px
    min-height: 60px
    @media screen and (max-width: $end_tablet_width)
        width: unset

.Itn
    @extend .Common
    align-items: center
    gap: 5px

.ItnTitle
    font-size: 16px
    font-weight: normal

.Address
    @extend .Common
    font-weight: normal
    display: flex
    gap: 10px
    min-height: 60px
    width: 250px
    @media screen and (max-width: $end_tablet_width)
        width: unset
        min-height: 80px

.NavIcon
    width: 20px
    height: auto

.ActiveMobile
    display: none

.Active
    display: flex
    margin-right: 10px

.ButtonAndActiveContainer
    display: flex
    align-items: center
    justify-content: center
    width: max-content
    gap: 10px

.ButtonContainer
    display: flex

.VerticalLine
    border-left: solid 1px #D1D4D7
    height: 30px
