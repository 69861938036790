.Container
    display: flex
    color: #46A053
    gap: 5px
    justify-content: center
    align-items: center
   

.ContainerManual
    @extend .Container
    color: #1C86F1
