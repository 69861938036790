@import "../../../../styles/_vars"

.Container
    width: 484px
    height: 360px
    border: solid 1px #C9D2D7
    border-radius: 15px
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    animation: showDiv 1s forwards
    @media screen  and (max-width: $end_mobile_width)
        width: 300px
        height: 550px

.SideContainer
    display: flex
    flex-direction: column
    align-items: center

.Header
    height: 200px
    width: 100%
    background-color: #ECF3F6
    border-radius: 15px 15px 0 0
    display: flex
    justify-content: center
    @media screen  and (max-width: $end_mobile_width)
        height: 200px

.Footer
    height: 40px
    width: 100%
    background-color: #1C86F1
    border-radius: 0 0 15px 15px
    color: white
    display: flex
    align-items: center
    justify-content: center

.InFooterWrapper
    display: flex
    width: 90%
    justify-content: space-between
    cursor: pointer

.InFooterWrapper2
    display: flex
    align-items: center
    gap: 10px

.ParkingInfo
    display: flex
    align-items: center
    width: 90%

.InParkingInfoWrapper
    display: flex
    flex-direction: column
    gap: 5px
    white-space: wrap
    height: 90px
    width: 100%
    @media screen  and (max-width: $end_mobile_width)
        height: unset

.ParkingName
    display: flex
    font-size: 20px
    font-weight: bold
    align-items: center
    justify-content: space-between
    @media screen  and (max-width: $end_mobile_width)
        font-size: 18px

.ParkingAddress
    font-weight: 400
    font-size: 14px
    display: flex
    align-items: center
    gap: 5px

.PlacesBlock
    width: 90%
    gap: 10px
    display: flex
    @media screen  and (max-width: $end_mobile_width)
        flex-direction: column
        width: unset
        margin-top: 5px

.PlacesAvailable
    height: 36px
    border-left: solid 2px green
    display: flex
    justify-content: space-between
    align-items: flex-start
    padding-left: 10px
    gap: 10px
    display: flex
    align-items: center
    font-size: 16pp
    width: 100%

.PlacesInUse
    @extend .PlacesAvailable
    border-left: solid 2px red

.Quantity
    font-size: 20px
    font-weight: bold

.ForBorder
    border-bottom: solid 1px rgba(181,190 ,197 ,0.3 )
    height: 57px
    width: 212px
    display: flex
    align-items: center

.ForBorderBarrier
    @extend  .ForBorder
    height: 73px

.BarrierStatusBlock
    @extend .PlacesBlock
    align-items: center
    overflow-y: hidden
    overflow-x: scroll
    height: 100px
    width: 400px
    &::-webkit-scrollbar
        height: 3px
        background-color: #CFD6DA
    &::-webkit-scrollbar-thumb
        height: 3px
        background-color: #B5BEC5
        border-radius: 3px 3px 3px 3px
    @media screen  and (max-width: $end_mobile_width)
        width: 100%

.BarrierBlock
    height: 85px
    min-width: 150px

    border: solid 1px #CFD6DA
    border-radius: 8px
    font-size: 14px

.RevenueBlock
    display: flex
    width: 90%
    @media screen  and (max-width: $end_mobile_width)
        flex-direction: column
        width: unset

.RevenueItem
    width: 100%

.Amount
    @extend .Quantity

.DevicesIcon
    cursor: pointer

.DevicesContainer
    height: 60%

.NoDevices
    display: flex
    width: 100%
    height: 100%
    align-items: center
    justify-content: center

.StatusImage
    width: 14px
    height: 14px

.StatusBlock
    display: flex
    font-size: 12px
    color: #F6583E
    gap: 3px

.StatusBlockOnline
    @extend  .StatusBlock
    color: #46A053

.StatusAndName
    display: flex
    flex-direction: column
    gap: 5px
    width: 140px
    padding: 5px

.Buttons
    display: flex
    width: 100%
    justify-content: space-between
    align-items: flex-end
    height: 40px

.BarrierButtonLeft
    background-color: #1C86F1
    color: white
    border-radius: 0 0 0 8px
    border: none
    cursor: pointer
    width: 74px
    &:disabled
        cursor: not-allowed
        background-color: lightgray
    &:active
        opacity: 0.7

.BarrierButtonRight
    @extend  .BarrierButtonLeft
    border-radius: 0 0 8px 0
