@import "../../../styles/_vars"

.Container
    // margin-top: 50px
    width: 100%
    height: 100%
    text-align: center
    justify-content: center
    line-height: 2
    display: flex
    gap: 10px

.SubContainer
    width: 30%
    @media screen and (min-width: $start_tablet_width) and (max-width: $end_tablet_width)
        width: 40%
    @media screen and (max-width: $end_mobile_width)
        width: 90%
.SubContainer2
    width: 30%
    @media screen and (min-width: $start_tablet_width) and (max-width: $end_tablet_width)
        width: 40%
    @media screen and (max-width: $end_tablet_width)
        display: none
.SubContainer3
    width: 30%
    @media screen and (min-width: $start_tablet_width) and (max-width: $end_tablet_width)
        width: 40%
    @media screen and (max-width: $end_mobile_width)
        display: none
