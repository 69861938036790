@import "../../../styles/_vars"

.Container
    display: flex
    flex-direction: column
    align-items: center
    width: 95%
 

.HeaderAndButtonWrapper
    display: flex
    align-items: center
    justify-content: space-between
    width: 95%
  
.Header
    font-size: 36px
    font-weight: bold
    height: 42px
    margin: 20px 0

.ListContainer
    display: flex
    width: 95%
    height: 100%
    gap: 10px
    align-items: flex-start

.PaymentImage
    position: absolute
    right: 50px
