@import "../../../../styles/_vars"

.Container
        height: 74px
        width: 95%
        display: flex
        align-items: center
        justify-content: center
        border: solid 1px #E7EBEE
        &:hover
                box-shadow: 0px 5px 9px 0px rgba(14, 32, 81, 0.2)
        @media screen and (max-width: $end_tablet_width)
                width: 300px
                height: 300px
                flex-direction: column
                justify-content: center
                align-items: left
                gap: 10px
               
.ContentWrapper
        display: flex
        justify-content: space-between
        width: 95%
        gap: 10px
        @media screen and (max-width: $end_tablet_width)
                flex-direction: column
                gap: 10px
                margin-left: unset
                width: 80%
                align-items: flex-start
                

.Width300
        min-width: 250px
        display: flex
        align-items: center
        justify-content: center
        @media screen and (max-width: $end_tablet_width)
                min-width: unset

.Width200
        @extend .Width300
        min-width: 200px
        @media screen and (max-width: $end_tablet_width)
                min-width: unset

.Width50
        @extend .Width300
        min-width: 50px
        @media screen and (max-width: $end_tablet_width)
                min-width: unset

.Id
        @extend .Width50
        font-weight: bold
        font-size: 16px
        gap: 10px

.Time
        width: 52px
        height: 29px
        color: white
        background-color: black
        border-radius: 5px
        display: flex
        align-items: center
        justify-content: center

.DateTime
        @extend  .Width200
        display: flex
        align-items: center
        justify-content: center
        gap: 10px
        font-size: 16px
        font-weight: bold

.DateTitle
        font-weight: normal

.Login
        font-size: 16px
        font-weight: bold

.Video
        font-size: 16px
        color: #6D798C

.VideoPlaceHolder
        width: 100px
        @media screen and (max-width: $end_tablet_width)
                width: unset
                &:has(div)
                        margin-right: 10px

.VideoWrapper
        display: flex
        align-items: center
        justify-content: center
        gap: 5px
        height: 40px
        cursor: pointer

.UserContainer
        min-height: 30px
        display: flex
        align-items: center
        justify-content: center
        min-width: 300px
        @media screen and (max-width: $end_tablet_width)
                min-width: unset

.User
        @extend  .Width300
        gap: 5px
        @media screen and (max-width: $end_tablet_width)

.Link
        text-decoration: none

.Row
        display: flex
        gap: 10px
        @media screen and (max-width: $end_tablet_width)
                gap: unset

.RowRight
        display: flex
        align-items: center
        justify-content: flex-start
        width: 100px
        @media screen and (max-width: $end_tablet_width)
                width: unset

.DateTimeWrapper
        display: flex
        align-items: center
        justify-content: center
        gap: 10px



.PaidPlaceHolder
        width: 130px
        height: 40px
        @media screen and (max-width: $end_tablet_width)
                width: unset
                &:has(div)
                        margin-right: 10px

.DeviceTitle
        font-weight: normal

.CarSignPlaceHolder
        width: 140px
        @media screen and (max-width: $end_tablet_width)
                width: unset
                &:has(div)
                        margin-right: 10px
