.Container
    width: 100%
    text-align: left
    display: flex
    gap: 10px
    align-items: center
    cursor: pointer

.UnCheckedContainer
    height: 24px
    width: 24px
    border-radius: 6px
    border: solid 1px #E3E3E6
    display: flex
    align-items: center
    justify-content: center

.CheckedContainer
    @extend .UnCheckedContainer
    background-color: #1C86F1
