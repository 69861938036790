@import "../../../styles/_vars"

.PopupAnchor
    position: absolute
    height: 0
    width: 0

.PopUp
    position: relative
    top: -40px
    // left: -120px
    height: 40px
    opacity: 0
    color: grey
    font-size: 14px
    &:hover
        animation: showDiv 1s forwards

.PopupInfo
    padding: 10px
    background-color: rgba(245, 245, 245, 0.8)
    border-radius: 5px
