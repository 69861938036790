@import "../../../../../../../styles/_vars"

.Container
    display: flex
    flex-direction: column
    align-items: center
    border-radius: 25px
    background-color: white
    border: solid 1px lightgray
    width: 100px
    height: 180px
    padding: 20px
    position: absolute
    right: 90px
    top: 80px
    z-index: 5
    @media screen and (max-width: $end_tablet_width)
        right: 0
        top: 70px
