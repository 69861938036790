.Loader
    width: 94px
    height: 94px
    background: linear-gradient(180deg, #0f6fff 0%, rgba(0, 0, 0, 0.1) 25%)
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    position: relative
    animation: spin 1s linear infinite

    &::after
        content: ""
        width: calc(100% - 4px)
        height: calc(100% - 4px)
        border-radius: 50%
        background: white

@keyframes spin
    100%
        transform: rotate(360deg)

