@import "../../styles/_vars"

.Container
  display: flex
  flex-direction: column
  align-items: center
  width: 90%
  @media screen and (max-width: $end_mobile_width)
    width: 100%

.RolesListContainer
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  gap: 10px
  align-items: center
  @media screen and (max-width: $end_tablet_width)
    flex-direction: row
    flex-wrap: wrap
    justify-content: center

.HeaderAndButtonWrapper
  display: flex
  align-items: center
  justify-content: space-between
  width: 95%
  @media screen and (max-width: $end_mobile_width)
    flex-direction: column
    align-items: left
    justify-content: unset

.BookmarksAndAdd
  display: flex
  gap: 30px
  align-items: center
  @media screen and (max-width: $end_mobile_width)
    flex-direction: column
    gap: 15px
    margin-bottom: 10px

.Header
  font-size: 36px
  font-weight: bold
  height: 42px
  margin: 20px 0

.HeaderGrey
  @extend .Header
  color: #70767E

.RightsHeader
  display: flex
  width: 95%
  justify-content: space-between
  align-items: center
  background-color: #F2F7F9
  height: 50px
  border: solid 1px #E7EBEE

.ItemContainer
  width: 150px
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  font-size: 16px
  font-weight: bold
  gap: 5px

.ContentWrapper
  width: 100%
  display: flex
  margin: 0 20px
  justify-content: space-between
  align-items: center
  gap: 10px
  font-size: 16px

.ButtonsContainer
  display: flex
  width: 95%
  justify-content: flex-end
  gap: 30px


.Message
  font-size: 16px
  height: 400px
  display: flex
  align-items: center
  justify-content: center
  width: 300px
  text-align: center
