@import "../../../styles/_vars"

.Container
  display: flex
  flex-direction: column
  align-items: center
  width: 90%

.ListContainer
  display: flex
  flex-direction: column
  width: 95%
  height: 750px
  gap: 10px
  align-items: center
  overflow-y: scroll
  &::-webkit-scrollbar
    width: 5px
  &::-webkit-scrollbar-thumb
    background-color: #1C86F1

.HeaderAndButtonWrapper
  display: flex
  align-items: center
  justify-content: space-between
  width: 95%


.Message
  font-size: 16px
  height: 400px
  display: flex
  align-items: center
  justify-content: center
  width: 300px
  text-align: center


.LoaderContainer
  height: 400px
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  text-align: center
