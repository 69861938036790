.ToDisable
    
.ToDisableDisabled
    filter: opacity( 0.3)
    cursor: not-allowed


.Container
    width: 30px
    height: 15px
    border-radius: 50px
    background-color: #46A053
    display: flex
    align-items: center
    justify-content: center
    transform: scale(-1, 1)

.InToggleWrapper
    height: 100%
    display: flex
    align-items: center
    justify-content: right

.Switch
    position: relative
    top: 0.2px
    right: 0.1px
    width: 18px
    height: 13px
    background-color: white
    border-radius: 25px
    cursor: pointer

.ContainerDisabled
    @extend .Container
    background-color: #C9D2D7

.InToggleWrapperDisabled
    @extend .InToggleWrapper
    justify-content: left
