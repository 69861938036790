@import "./../../styles/_vars"

.Container
    min-height:  990px
    height: max-content
    display: flex
    flex-direction: column
    @media screen and (max-width: $end_tablet_width)
        height: unset
        min-height: unset

.ContentContainer
    @extend  .Container
    flex-direction: unset
    display: flex
    justify-content: center

.ContentContainerOverflowHidden
    @extend .ContentContainer
    @media screen and (max-width: $end_mobile_width)
        overflow: hidden
