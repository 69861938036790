@import "../../../../styles/_vars"

.Container
    height: 564px
    width: 484px
    background-color: white
    display: flex
    flex-direction: column
    border-radius: 15px
    align-items: center
    justify-content: center
    gap: 20px
    @media screen and (max-width: $end_mobile_width)
        width: 300px
        height: 600px

.Title
    font-size: 28px
    font-weight: bold
    width: 500px
    white-space: pre-wrap
    text-align: center
    @media screen and (max-width: $end_mobile_width)
        width: 290px
        font-size: 18px

.Address
    display: flex
    gap: 5px
    align-items: center
    justify-content: center
    width: 80%

.Comment
    font-size: 20px
    font-weight: 600
    text-align: center
    width: 80%
    @media screen and (max-width: $end_mobile_width)
        font-size: 16px

.ButtonsContainer
    display: flex
    gap: 10px

.Image
    height: 175px
    width: auto
    @media screen and (max-width: $end_mobile_width)
        height: 135px

.ImageSure
    width: 375px
    height: auto
    @media screen and (max-width: $end_mobile_width)
        width: 290px

.StatusRow
    width: 100%
    height: 50px
    color: white
    background-color: #46A053
    font-size: 18px
    font-weight: 600
    display: flex
    align-items: center
    justify-content: center

.StatusRowInActive
    @extend   .StatusRow
    background-color: #EA3E10
