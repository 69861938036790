@import "../../../styles/_vars"

.ProgressCircle
    position: relative
    width: 150px
    height: 150px
    background-color: #ECF3F6
    border-radius: 50%
    overflow: hidden
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.ProgressInner
    position: absolute
    left: 50%
    top: 50%
    width: 110px
    height: 110px
    transform: translateX(-50%) translateY(-50%)
    background: white
    border-radius: 50%

// .admin_console_progress_inner_shadow {
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   width: 170px;
//   height: 170px;
//   transform: translateX(-50%) translateY(-50%);
//   background: transparent;
//   border-radius: 50%;
//   box-shadow: inset 0 5px 10px 0 rgba(0, 0, 0, 0.5);
// }

.ProgressSegment
    position: absolute
    width: 100vw
    height: 100vw

.LevelCircleValue
    font-size: 48px
    font-weight: bold
    z-index: 1
