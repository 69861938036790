
.CarSign
        display: flex
        font-size: 16px
        color: #0F0F1E
        border: solid 1px #70767E
        height: 40px
        width: 127px
        display: flex
        align-items: center
        justify-content: space-between
        min-width: 127px

.CarSignLeftSide
        display: flex
        align-items: center
        justify-content: center
        width: 100%

.CarSignRightSide
        background-color: #70767E
        color: white
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        font-size: 12px
        min-width: 43px

.CarSignCountry
        font-size: 10px
