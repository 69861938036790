@import "../../../../styles/_vars"

.Container
    width: 1600px
    height: 100vh
    background-color: white
    display: flex
    align-items: center
    justify-content: center
    border-radius: 15px
    @media screen and (max-width: $end_tablet_width)
        width: 100vw

.InFormWrapper
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    flex-direction: column
    gap: 10px
    width: 70%
    @media screen and (max-width: $end_mobile_width)
        gap: 5px

.UserImage
    width: 158px
    height: 158px
    @media screen and (max-width: $end_mobile_width)
        width: 100px
        height: 100p

.Title
    font-size: 40px
    text-align: center
    @media screen and (max-width: $end_mobile_width)
        font-size: 32px

.ButtonsContainer
    display: flex
    align-items: center
    justify-content: center
    gap: 10px
    width: 550px
    @media screen and (max-width: $end_mobile_width)
        width: 100%
        flex-direction: column

.ImageSure
    width: 374px
    height: auto
    @media screen and (max-width: $end_mobile_width)
        width: 220px

.TitleSure
    font-size: 32px
    font-weight: bold
    @media screen and (max-width: $end_mobile_width)
        font-size: 22px

.CommentSure
    font-size: 20px
    font-weight: 600
    @media screen and (max-width: $end_mobile_width)
        font-size: 16px

.RoleCheckBoxesContainer
    display: flex
    gap: 200px
    justify-content: flex-end
    @media screen and (max-width: $end_tablet_width)
        gap: unset

.SubjectLabels
    display: flex
    flex-direction: column
    margin-top: 35px
    gap: 15px
    @media screen and (max-width: $end_tablet_width)
        display: none

.RoleCheckBoxesContainerMobile
    display: none
    @media screen and (max-width: $end_tablet_width)
        display: flex
        flex-direction: column

.SubjectLabelsMobile
    display: none
    @media screen and (max-width: $end_tablet_width)
        display: flex
        flex-flow: column
        width: 280px
        padding: 10px
        height: 280px
        gap: 10px
        overflow-y: scroll

.SubjectItem
    font-weight: bold
    display: flex
    align-items: center

.ActionItem
    font-weight: bold
    width: 90px
    display: flex
    align-items: center
    flex-direction: column
    gap: 21px
    @media screen and (max-width: $end_tablet_width)
        flex-direction: unset
        width: 100%
        justify-content: space-between
        font-weight: normal

.SelectorContainer
    display: flex
    width: 100%
    height: 380px
    justify-content: space-between
    @media screen and (max-width: $end_tablet_width)
     display: none


.RoleToggleContainer

.InputContainer
    width: 300px
