@import "../../../styles/_vars"

.Container
    min-width: 320px
    background-color: #EFF0F2
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 30px
    @media screen and (max-width: $end_tablet_width)
        display: none

.ContainerMobile
    top: 0
    left: 0
    height: 100vh
    position: fixed
    min-width: 320px
    background-color: #EFF0F2
    display: flex
    flex-direction: column
    align-items: center
    z-index: 10

.LogoAndClose
    display: flex
    width: 90%
    justify-content: space-between
    align-items: center
    height: 50px
    @media screen and (min-width: $start_desktop_width)
        display: none
    @media screen and (min-width: $start_tablet_width) and  (max-width: $end_tablet_width)
        height: 70px


.Logo
    @media screen and (max-width: $end_mobile_width)
        height: 32px
        width: 32px
