@import "../../../../../styles/_vars"

.Link
    font-size: 16px
    font-weight: 600
    color: #0F0F1E
    width: 250px
    height: 55px
    display: flex
    align-items: center
    justify-content: space-between
    border-radius: 100px
    padding-left: 20px
    cursor: pointer
    animation: showDiv 1s forwards

.LinkActive
    @extend .Link
    background-color: #1C86F1
    color: white
    cursor: unset

.MenuArrow
    position: relative
    right: 20px
