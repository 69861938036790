@import "../../../styles/_vars"

.Container
    background-color: white
    font-size: 14px
    display: flex
    gap: 5px
    align-items: center
    justify-content: center
    height: max-content
    height: 40px
    color: #46A053
    padding: 0 10px 0 10px
    border-radius: 50px
    border: solid 1px #DFE4E8
    width: max-content
    @media screen and (max-width: $end_tablet_width)
        max-width: 30px

.ContainerPointer
    cursor: pointer

.ContainerNegative
    @extend .Container
    color: #F6583E

.StatusTitle
    font-weight: normal

.ContainerUser
    @extend .Container

.ContainerNegativeUser
    @extend .ContainerUser

.ContainerExpandedAnchor
    width: 0px

.ContainerExpanded
    @extend .ContainerPointer
    position: relative

.StatusTitle
    @media screen and (max-width: $end_tablet_width)
        display: none
