@import "../../../../../styles/_vars"

.Container
    width: 500px
    height: 296px
    overflow: hidden
    display: flex
    flex-direction: column
    border-radius: 25px
    @media screen and (max-width: $end_mobile_width)
        width: 95%

.Top
    background-color: #E6F1FC
    width: 460px
    height: 163px
    padding: 30px 20px 20px 20px
    display: flex
    flex-direction: column
    justify-content: space-between
    @media screen and (max-width: $end_mobile_width)
        width: unset

.Bottom
    background-color: #6E7D93
    width: 460px
    height: 23px
    display: flex
    align-items: center
    padding: 30px 20px
    @media screen and (max-width: $end_mobile_width)
        width: unset

.Value
    font-size: 16px
    font-weight: bold
    display: flex
    align-items: center
    gap: 10px

.Name
    @extend .Value
    font-weight: normal

.Row
    width: 100%
    display: flex
    gap: 10px
    @media screen and (max-width: $end_mobile_width)
        flex-direction: column

.RowJustify
    @extend .Row
    justify-content: space-between
    color: white

.Rur
    font-size: 14px
    font-weight: normal

.Smaller14
    font-size: 14px

.ContainerData
    width: max-content
    height: 29px
    color: white
    background-color: black
    border-radius: 5px
    display: flex
    align-items: center
    justify-content: center
    gap: 5px
    padding-left: 5px
    padding-right: 5px
    font-weight: bold
