@import "../../../styles/_vars"
.PaginationBlock
  display: flex
  align-items: center
  justify-content: left
  gap: 20px
  width: 95%
  height: 100px


.PaginationButton
  width: 35px
  height: 35px
  border: solid 1px #F1F5F9
  border-radius: 4px
  color: #485A75
  background-color: transparent
  cursor: pointer
  font-weight: 600
  font-size: 18px
  display: flex
  align-items: center
  justify-content: center
  &:active
    background-color: #1C86F1
    color: #FFF
  &:disabled
    opacity: 0.2

.PaginationButtonActive
  @extend .PaginationButton
  background-color: #1C86F1
  color: #FFF

.CaretLeftArrow
  transform: rotate(180deg)
