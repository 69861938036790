@import "../../../../styles/_vars"

.Container
    height: 74px
    width: 95%
    display: flex
    align-items: center
    justify-content: center
    border: solid 1px #E7EBEE
    animation: showDiv 1s forwards
    &:hover
        box-shadow: 0px 5px 9px 0px rgba(14, 32, 81, 0.2)
    @media screen and (max-width: $end_tablet_width)
        width: 300px
        height: 350px
        flex-direction: column
        justify-content: center
        align-items: center
        gap: 10px

.ContentWrapper
    width: 100%
    display: flex
    margin: 0 20px
    justify-content: space-between
    align-items: center
    gap: 10px
    font-size: 16px
    @media screen and (max-width: $end_tablet_width)
        flex-direction: column
        align-items: unset
        gap: 10px
        margin-left: unset
        width: 80%

.Width150
    min-width: 150px
    display: flex
    align-items: center
    justify-content: center
    @media screen and (max-width: $end_tablet_width)
        min-width: unset

.PassName
    display: flex
    align-items: center
    font-size: 16px
    font-weight: bold
    width: 250px
    white-space: wrap
    @media screen and (max-width: $end_tablet_width)
        width: unset

.Date
    @extend .Width150
    font-size: 16px
    font-weight: bold
    gap: 5px
    @media screen and (max-width: $end_tablet_width)
        flex-direction: column
        align-items: unset
        width: 100%

.BeforeDate
    font-size: 16px
    font-weight: normal

.BlackSquared
    background-color: black
    padding: 5px
    color: white
    font-weight: bold
    border-radius: 5px

.NotSpentBlock
    @extend .Width150
    display: flex
    gap: 5px
    align-items: center
    @media screen and (max-width: $end_tablet_width)
        justify-content: left

.StatusAndButtonsWrapper
    display: flex
    gap: 10px
    align-items: flex-start
    justify-content: center
    margin-right: 20px

.DaysVisits
    font-size: 14px

.Cost
    font-size: 16px
    display: flex
    align-items: center
    gap: 5px
    font-weight: bold
    width: 100px
    @media screen and (max-width: $end_tablet_width)
        width: unset

.Rur
    font-size: 14px
    font-weight: normal

.VerticalLine
    border-left: solid 1px #D1D4D7
    height: 30px
    @media screen and (max-width: $end_tablet_width)
        display: none

.Active
    display: flex
    margin-right: 10px

.HorizontalLine
    @media screen and (max-width: $end_tablet_width)
        width: 90%
        height: 0px
        border-top: solid 1px #D1D4D7
        margin-left: 5px
