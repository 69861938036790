$start_mobile_width: 320px
$end_mobile_width: 700px
$start_tablet_width: 701px
$end_tablet_width: 1600px
$start_desktop_width: 1601px

@keyframes showDiv
    0%
        opacity: 0
    100%
        opacity: 1
