@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url("../src/assets/fonts/Raleway/Raleway-VariableFont_wght.ttf")
      format("truetype");
}

body {
  margin: 0;
  user-select: none ;
  font-family: -apple-system, BlinkMacSystemFont, 'Raleway', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
