@import "../../../styles/_vars" 

.Container
    height: 50px
    color: #6D798C
    display: flex
    align-items: center
    justify-content: center
    gap: 5px
    padding: 0 10px
    cursor: pointer
    font-size: 20px

.ContainerDisabled
  @extend  .Container
  opacity: 0.4
  cursor: not-allowed
    
.Title
   @media screen and (max-width: $end_tablet_width)
    display: none

     