@import "../../../styles/_vars"

.Container
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    display: flex
    align-items: flex-end
    justify-content: flex-end
    background-color: rgba(245, 245,245 ,0.7 )
    z-index: 6
    animation: showDiv 0.5s forwards


.ContainerCentral
    @extend .Container
    align-items: center
    justify-content: center

.ContentContainer
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: max-content
    width: max-content

.CloseIcon
    position: relative
    cursor: pointer
    top: 55px
    left: 43%


.CloseIconCentral
    @extend  .CloseIcon
    left: 40%
