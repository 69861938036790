@import "../../../styles/_vars"

.Container
    height: 50px
    border-radius: 8px
    border: solid 1px #E3E3E6
    font-size: 18px
    color: #70767E
    @media screen and (max-width: $end_mobile_width)
        height: 35px

.ContainerRequired
    @extend .Container
    border-left-color: red

.SelectContainer
    display: flex
    flex-direction: column
    width: 100%

.Label
    font-weight: 600
    font-size: 18px

.ValidationErrorField
    width: 100%
    height: 10px
    font-size: 10px
    color: red
