@import "../../../styles/_vars"

.Container
    width: 680px
    height: 605px
    background-color: white
    display: flex
    align-items: center
    justify-content: center
    @media screen and (max-width: $end_mobile_width)
        width: 300px
        height: 700px

.ContainerOnPage
    @extend .Container
    width: 500px
    height: 400px
    @media screen and (max-width: $end_mobile_width)
        width: 280px

.InContainer
    width: 90%
    height: 90%
    display: flex
    flex-direction: column
    align-items: center

.InContainerOnPage
    @extend .InContainer
    width: 100%
    height: 100%
    gap: 20px
    @media screen and (max-width: $end_mobile_width)
        width: 300px

.DataContainer
    width: 100%
    height: 69px
    background-color: #F2F7F9
    display: flex
    gap: 30px
    margin-top: 20px
    justify-content: center
    align-items: center
    @media screen and (max-width: $end_mobile_width)
        width: 260px
        flex-direction: column
        height: unset
        gap: 10px
        align-items: flex-start
        padding: 5px

.Title
    font-size: 24px
    font-weight: bold
    width: 100%
    padding: 20px

.TitleOnPage
    font-size: 20px
    font-weight: bold

.Time
    width: 52px
    height: 29px
    color: white
    background-color: black
    border-radius: 5px
    display: flex
    align-items: center
    justify-content: center

.DateTime
    display: flex
    align-items: center
    justify-content: center
    gap: 10px
    font-size: 16px
    font-weight: bold

.DateTitle
    font-weight: normal

.DateTimeWrapper
    display: flex
    align-items: center
    justify-content: center
    gap: 10px

.Id
    font-size: 16px
    font-weight: bold

.Video
    width: 100%
    height: 416px

.VideoOnPage
    @extend .Video
    height: 300px

.VideoContent
    width: 100%
    height: 100%
