@import "../../styles/_vars"

.Container
    display: flex
    align-items: center
    flex-direction: column
    gap: 50px

.ImageContainer
    border-radius: 45px
    border: solid 10px black
    height: 640px
    width: 70%
    background-image: url("../../assets/images/Illustration.svg")
    background-position: center
    background-size: contain
    background-repeat: no-repeat
 

.Title
    font-size: 64px
    font-weight: light
    width: 520px
    height: 150px
    text-align: center
    display: flex
    flex-direction: column
t

.TitleBold
    font-size: 64px
    font-weight: bold
   

