.Container
    height: 23px
    font-size: 20px
    border-radius: 50px
    display: flex
    align-items: center
    justify-content: center
    padding: 10px 15px
    border: solid 1px #D1D4D7
    color: #6D798C
    gap: 4px
    z-index: 4
    cursor: pointer
    &:nth-child(odd)     
        position: relative
        left: 8px
    &:nth-child(even)      
        position: relative
        left: -8px

.ContainerActive
    @extend .Container
    background-color: #1D7DDF
    color: white
    cursor: default   
    z-index: 5


.QuantityCircle
    border-radius: 50%
    width: 24px
    height: 24px
    font-size: 14px
    background-color: #6D798C
    color: white
    display: flex
    align-items: center
    justify-content: center

.QuantityCircleActive
    @extend .QuantityCircle
    background-color: white
    color: #1D7DDF
