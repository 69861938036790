@import "../../../styles/_vars"

.Container
    display: flex
    align-items: center
    justify-content: center
    gap: 10px
    padding: 10px 15px
    min-height: 50px
    width: max-content
    border: none
    background-color: black
    font-size: 18px
    color: white
    border-radius: 50px
    cursor: pointer

    &:hover
        opacity: .8
    &:disabled
        opacity: 0.4
        cursor: not-allowed

.ContainerWide
    @extend .Container
    width: 100%

.ContainerLight
    @extend .Container
    color: black
    background-color: white
    border: solid 1px #D1D4D7

.ContainerRed
    @extend .Container
    color: #F6583E
    background-color: white
    border: solid 1px #F6583E

.ContainerFilledRed
    @extend .Container
    @extend .Container
    color: #F6583E
    background-color: #F6583E
    border: solid 1px #F6583E
    color: white

.ContainerFilledGreen
    @extend .Container
    color: #46A053
    background-color: #46A053
    border: solid 1px #46A053
    color: white

.ContainerWideLight
    @extend  .ContainerLight
    width: 100%

.ContainerWideRed
    @extend  .ContainerRed
    width: 100%
