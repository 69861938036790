@import "../../../styles/_vars"

.Container
    height: 800px
    width: 484px
    background-color: white
    display: flex
    flex-direction: column
    border-radius: 15px
    align-items: center
    // justify-content: center
    gap: 20px
    @media screen and (max-width: $end_mobile_width)
        width: 300px
        height: 700px

.Title
    font-size: 32px
    font-weight: bold
    @media screen and (max-width: $end_mobile_width)
        font-size: 24px

.Address
    display: flex
    gap: 5px
    align-items: center
    justify-content: center
    width: 80%

.Comment
    font-size: 20px
    font-weight: 600
    text-align: center
    width: 350px
    height: 50px
    @media screen and (max-width: $end_mobile_width)
        width: 300px

.ButtonsContainer
    height: 50px
    display: flex
    gap: 10px

.Image
    height: 255px
    width: auto
    @media screen and (max-width: $end_mobile_width)
        width: 248px
        height: auto

.ProgressCircleAnchor
    width: 0px
    height: 0px

.ProgressCircleContainer
    position: relative
    bottom: -150px
    left: -250px
    @media screen and (max-width: $end_mobile_width)
        bottom: -120px
        left: -200px

.CircleAndImageContainer
    display: flex
    justify-content: center
    width: 357px
    min-height: 300px
    margin-top: 100px
    @media screen and (max-width: $end_mobile_width)
        width: 90%
        margin-top: 20px

.FieldsContainer
    width: 90%
